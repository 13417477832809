import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import {
  TooltipPortal,
  type TooltipContentProps,
} from '@radix-ui/react-tooltip';
import { Text } from '../text';
import { TooltipArrow, TooltipContent } from './TooltipPrimitives';
import type { TextTransform } from './Tooltip';

export interface TooltipContainerT
  extends Omit<TooltipContentProps, 'children'> {
  textTransform?: TextTransform | undefined;
  withPortal?: boolean;
  children: React.ReactNode | string | undefined;
}

export const animationClasses = [
  'animate-fade-in',
  'data-[state=closed]:data-[side=top]:animate-fade-out-top',
  'data-[state=closed]:data-[side=bottom]:animate-fade-out-bottom',
  'data-[state=closed]:data-[side=left]:animate-fade-out-left',
  'data-[state=closed]:data-[side=right]:animate-fade-out-right',
  'data-[side=top]:animate-fade-in-top',
  'data-[side=bottom]:animate-fade-in-bottom',
  'data-[side=left]:animate-fade-in-left',
  'data-[side=right]:animate-fade-in-right',
];

export const TooltipContainer = ({
  className,
  sideOffset,
  children,
  textTransform,
  withPortal = true,
  ...props
}: TooltipContainerT) => {
  const textTransformClass = textTransform ? textTransform : '';

  const content = (
    <TooltipContent
      sideOffset={sideOffset || 2}
      className={cn(
        'z-notification w-auto rounded-xl bg-high-contrast px-3 py-2 text-on-high-contrast',
        animationClasses,
        className,
      )}
      {...props}
    >
      <TooltipArrow />
      {typeof children === 'string' ? (
        <Text
          className={cn('text-87.5 text-on-high-contrast', textTransformClass)}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </TooltipContent>
  );

  return withPortal ? <TooltipPortal>{content}</TooltipPortal> : content;
};

import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  prefixWrapperVariants,
  type PrefixWrapperVariantsProps,
} from './PrefixWrapper.variants';

export const PrefixWrapper = ({
  prefix,
  children,
  status,
}: {
  prefix?: string | undefined;
  children: React.ReactNode;
  status: PrefixWrapperVariantsProps['variant'];
}) => {
  return (
    <Box className={prefixWrapperVariants({ variant: status })}>
      <Box className={clsx(['flex-row', 'flex-auto', 'items-center'])}>
        <Text
          className={clsx(['text-87.5', 'text-inherit', 'leading-[inherit]'])}
        >
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          {prefix}
        </Text>
        {children}
      </Box>
    </Box>
  );
};

import { Field, type FieldProps } from 'formik';
import React, { type JSX } from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Label } from '@mentimeter/ragnar-ui/label';
import { Meta } from '@mentimeter/ragnar-ui/typography';
import {
  CharacterCountWrap,
  CharacterCount,
} from '@mentimeter/ragnar-ui/character-count';
import {
  type TextInputItemT,
  TextInputItem,
} from '@mentimeter/ragnar-ui/input/text-input';
import { TriggerDescriptive, Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { getErrorMessage, getShouldShowError } from '../utils';
import { InputFeedback } from '../input-feedback';
import { PrefixWrapper } from '../prefix-wrapper';

export interface TextInputProps extends BoxT {
  name: string;
  label: string;
  prefix?: string;
  optional?: boolean;
  description?: string;
  placeholder?: string | undefined;
  hintText?: React.ReactNode;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  maxLength?: number;
  renderTextInputItem?: (props: TextInputItemT) => JSX.Element;
  validate?: (value: any) => void | string | Promise<any>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  innerRef?: any;
}

export function TextInput({
  name,
  label,
  prefix,
  description,
  optional = false,
  placeholder,
  type,
  validate,
  innerRef,
  hintText,
  onBlur,
  onChange,
  onFocus,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  renderTextInputItem,
  ...boxProps
}: TextInputProps) {
  return (
    <Field name={name} validate={validate} innerRef={innerRef}>
      {(fieldProps: FieldProps) => {
        const shouldShowErrorMessage = getShouldShowError(fieldProps);
        const textInputItemDefaultProps: TextInputItemT = {
          className: 'inputClass',
          inputSize: 'compact',
          id: name,
          'aria-describedby': `input-feedback-${name} character-counter-${name}`,
          value: fieldProps.field.value,
          name: fieldProps.field.name,
          autoComplete,
          autoFocus,
          disabled,
          maxLength,
          onBlur: (e) => {
            fieldProps.field.onBlur(e);
            if (onBlur) onBlur(e);
          },
          onChange: (e) => {
            fieldProps.field.onChange(e);
            if (onChange) onChange(e);
          },
          onFocus,
          type,
          status: shouldShowErrorMessage ? 'error' : undefined,
          placeholder,
        };

        return (
          <Box {...boxProps}>
            <Box className={clsx(['flex-row', 'items-center', 'mb-2'])}>
              <Box className="flex-row">
                <Label htmlFor={name} className="mb-0">
                  {label}
                  {optional ? (
                    <Meta
                      className={clsx(['font-normal', 'leading-snug', 'ml-1'])}
                    >
                      (optional)
                    </Meta>
                  ) : null}
                </Label>
              </Box>
              {description ? (
                <Tooltip
                  trigger={<TriggerDescriptive />}
                  id={`${name}-label-description`}
                >
                  {description}
                </Tooltip>
              ) : null}
            </Box>
            <CharacterCountWrap className="w-full">
              <ConditionalPrefixWrapper
                prefix={prefix}
                shouldShowErrorMessage={shouldShowErrorMessage}
              >
                {renderTextInputItem ? (
                  renderTextInputItem(textInputItemDefaultProps)
                ) : (
                  <TextInputItem
                    {...textInputItemDefaultProps}
                    data-testid={`${name}-input`}
                  />
                )}
              </ConditionalPrefixWrapper>
              {maxLength !== undefined && (
                <CharacterCount
                  id={`character-counter-${name}`}
                  maxLength={maxLength}
                  value={fieldProps.field.value}
                />
              )}
            </CharacterCountWrap>
            <InputFeedback
              error={getErrorMessage(fieldProps)}
              showError={shouldShowErrorMessage}
              hint={hintText}
              id={`input-feedback-${name}`}
            />
          </Box>
        );
      }}
    </Field>
  );
}

export interface ConditionalPrefixWrapperT {
  prefix: string | undefined;
  shouldShowErrorMessage: boolean;
  children: JSX.Element;
}

function ConditionalPrefixWrapper({
  prefix,
  shouldShowErrorMessage,
  children,
}: ConditionalPrefixWrapperT) {
  return prefix ? (
    <PrefixWrapper
      prefix={prefix}
      status={shouldShowErrorMessage ? 'error' : 'idle'}
    >
      {children}
    </PrefixWrapper>
  ) : (
    children
  );
}
